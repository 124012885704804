



















import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'QuotationResultItem',

  props: {
    title: {
      type: String,
      required: true,
    },

    value: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },

    unit: {
      type: String,
      default: '',
    },
  },
})
