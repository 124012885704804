var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"bg-white dark:bg-gray-800 shadow-md rounded-xl overflow-hidden"},[_c('BaseBox',{staticClass:"px-4 pt-5 pb-4 sm:px-6 sm:pt-6 sm:pb-4 border-b border-gray-200 dark:border-gray-700",attrs:{"tag":"header"}},[_c('BaseHeading',{staticClass:"text-primary dark:text-gray-100 text-4xl font-bold",attrs:{"level":1}},[_c('i18n',{attrs:{"tag":false,"path":"title"}})],1)],1),_c('BaseBox',{class:!_vm.results.contactCompany ? 'grid grid-cols-2' : 'grid'},[_c('BaseBox',{staticClass:"p-4 sm:px-6 grid gap-4"},_vm._l((_vm.results),function(result){return _c('QuotationResultItem',_vm._b({key:result.id},'QuotationResultItem',result,false))}),1),(_vm.results.proposedTechnology)?[_c('BaseBox',{staticClass:"flex justify-start"},[(_vm.results.proposedTechnology.value === 'MBBR')?_c('BaseImage',{staticClass:"w-auto h-60 mt-32",attrs:{"src":"@/assets/images/services/mbbr.svg","alt":""}}):(_vm.results.proposedTechnology.value === 'Sistema Lagunar')?_c('BaseImage',{staticClass:"w-auto h-60 mt-32",attrs:{"src":"@/assets/images/services/lagoon-systems.svg","alt":""}}):(
            _vm.results.proposedTechnology.value === 'Biorreactor Anaerobio'
          )?_c('BaseImage',{staticClass:"w-auto h-60 mt-24",attrs:{"src":"@/assets/images/services/anaerobic-bioreactor.svg","alt":""}}):_vm._e()],1),_c('BaseText',{staticClass:"ml-6"},[_vm._v(" En caso de descargar la ficha técnica tardará de 20-40 segundos en generarse ")])]:(!_vm.results.contactCompany)?[_c('BaseBox',{staticClass:"grid grid-cols-2"},[_c('BaseImage',{staticClass:"h-60",attrs:{"src":"@/assets/images/services/inverse-osmosis.svg","alt":""}}),_c('BaseImage',{staticClass:"w-auto h-60",attrs:{"src":"@/assets/images/services/water-softener.svg","alt":""}})],1),_c('BaseText',{staticClass:"ml-6"},[_vm._v(" En caso de descargar la ficha técnica tardará de 20-40 segundos en generarse ")])]:(
        _vm.results.contactCompany && _vm.answers.typeOfWaterToBeTreated !== 'Proceso'
      )?[_c('div',{staticClass:"ml-6"},[_c('BaseText',[_vm._v(" Contacto: ")]),_c('BaseLink',{staticClass:"hover:underline",attrs:{"href":"mailto:info@solucionesecologicas.mx"}},[_vm._v(" info@solucionesecologicas.mx ")])],1),_c('div',{staticClass:"ml-6"},[_c('BaseLink',{staticClass:"hover:underline",attrs:{"href":"tel:6623129788"}},[_vm._v(" Tel. (662) 312 9788 ")])],1),_c('BaseBox',{staticClass:"grid grid-cols-2 m-auto gap-12 mt-4 mb-4"},[_c('BaseImage',{staticClass:"h-60",attrs:{"src":"@/assets/images/services/inverse-osmosis.svg","alt":""}}),_c('BaseImage',{staticClass:"w-auto h-60",attrs:{"src":"@/assets/images/services/water-softener.svg","alt":""}})],1),_c('BaseText',{staticClass:"ml-6"},[_vm._v(" En caso de descargar la ficha técnica tardará de 20-40 segundos en generarse ")])]:(
        _vm.results.contactCompany && _vm.answers.typeOfWaterToBeTreated === 'Proceso'
      )?[_c('div',{staticClass:"ml-6"},[_c('BaseText',[_vm._v(" Contacto: ")]),_c('BaseLink',{staticClass:"hover:underline",attrs:{"href":"mailto:info@solucionesecologicas.mx"}},[_vm._v(" info@solucionesecologicas.mx ")])],1),_c('div',{staticClass:"ml-6"},[_c('BaseLink',{staticClass:"hover:underline",attrs:{"href":"tel:6623129788"}},[_vm._v(" Tel. (662) 312 9788 ")])],1)]:_vm._e()],2),(_vm.canGenerateQuote)?_c('BaseBox',{staticClass:"flex flex-col space-y-8 lg:flex-row lg:space-y-0 lg:space-x-4 lg:items-end px-4 pt-6 pb-10 border-t border-gray-300 border-opacity-50"},[_c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
      var reset = ref.reset;
return [_c('form',{ref:"form",staticClass:"flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4 lg:items-end lg:flex-1",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.quote(true); })},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('BaseBox',{staticClass:"flex-1"},[_c('ValidationProvider',{attrs:{"name":_vm.emailLabel,"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"html-for":"email","label":_vm.emailLabel}}),_c('BaseInputText',{attrs:{"id":"email","autocomplete":"email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('BaseFieldMessage',{attrs:{"id":"email-message","message":errors[0],"tone":"critical"}})]}}],null,true)})],1),_c('BaseBox',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"loading":_vm.isSendingEmail,"tone":"secondary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"cta.sendEmail"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"cta.sendEmailLoading"}})]},proxy:true}],null,true)})],1)],1)]}}],null,false,120014259)}),_c('BaseBox',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"loading":_vm.isDownloading,"tone":"primary"},on:{"click":function () { return _vm.quote(false); }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"cta.downloadDataSheet"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"cta.downloadDataSheetLoading"}})]},proxy:true}],null,false,483127026)})],1)],1):_vm._e(),_c('BaseBox',{staticClass:"px-4 py-3 sm:px-6 bg-gray-50 dark:bg-gray-700 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-8"},[_c('BaseLink',{staticClass:"text-center font-mono font-semibold leading-6 text-lg py-3 px-4 md:px-6",attrs:{"name":"Home"}},[_c('i18n',{attrs:{"tag":false,"path":"cta.home"}})],1),_c('BaseButton',{attrs:{"tone":"neutral"},on:{"click":_vm.onRestart}},[_c('i18n',{attrs:{"tag":false,"path":"cta.restart"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }