











































































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import { useQuotation } from '@/composition/useQuotation'
import { gsap } from 'gsap'
import { defineComponent, ref, watch } from '@vue/composition-api'
import QuotationQuestion from './QuotationQuestion.vue'
import QuotationResult from './QuotationResult.vue'

export default defineComponent({
  name: 'QuotationForm',

  components: {
    BrandLogo,
    QuotationQuestion,
    QuotationResult,
  },

  setup() {
    const {
      questions,
      results,
      answers,
      isCompleted,
      isReviewing,
      moveToPreviousQuestion,
      currentPosition,
      currentQuestion,
      updateAnswer,
      restart,
    } = useQuotation()

    const isAnimatingQuestion = ref(false)
    const animationDirection = ref(1)

    watch(currentPosition, (newValue, oldValue) => {
      animationDirection.value = oldValue > newValue ? -1 : 1
    })

    function onQuestionBeforeEnter() {
      isAnimatingQuestion.value = true
    }

    function onQuestionEnter(el: HTMLElement, done: () => void) {
      gsap.from(el.children, {
        y: 500 * animationDirection.value,
        opacity: 0,
        ease: 'power4.out',
        duration: 0.5,
        stagger: 0.1 * animationDirection.value,
        onComplete: () => {
          isAnimatingQuestion.value = false
          done()
        },
      })
    }

    function onQuestionLeave(el: HTMLElement, done: () => void) {
      isAnimatingQuestion.value = true

      gsap.to(el.children, {
        y: -500 * animationDirection.value,
        opacity: 0,
        ease: 'power4.in',
        duration: 0.5,
        stagger: 0.1 * animationDirection.value,
        onComplete: done,
      })
    }

    function onQuestionAfterLeave() {
      isAnimatingQuestion.value = false
    }

    function onRestart() {
      restart()
    }

    return {
      questions,
      results,
      answers,
      isCompleted,
      isReviewing,
      moveToPreviousQuestion,
      currentPosition,
      currentQuestion,
      updateAnswer,
      isAnimatingQuestion,
      onQuestionBeforeEnter,
      onQuestionEnter,
      onQuestionLeave,
      onQuestionAfterLeave,
      onRestart,
    }
  },
})
