import { computed, reactive, ref, watch } from '@vue/composition-api'

const questionTypes = ['radio'] as const

export type QuestionType = typeof questionTypes[number]

export interface QuestionOption {
  id: string
  label: string
  value: string | number
  illustration?: string
}

export interface Question {
  id: string
  title: string
  type: QuestionType
  options: QuestionOption[]
  value: string | number | null
}

const resultUnits = ['m2', 'm3', 'mg/L'] as const

export type ResultUnit = typeof resultUnits[number]

export interface Result {
  id: string
  title: string
  value: string | number | null
  unit?: ResultUnit
}

export function useQuotation() {
  const answers = reactive<Record<string, string | number | null>>({
    fieldOfBusiness: null,
    residentialType: null,
    rangeOfPeople: null,
    commerceType: null,
    waterType: null,
    sectorType: null,
    typeOfBird: null,
    animalQuantity: null,
    residualWaterToBeTreated: null,
    tonsOfChickensPerDay: null,
    siteType: null,
    animalType: null,
    typeOfWaterToBeTreated: null,
    numberOfShifts: null,
    shiftEmployerAmount: null,
  })

  const questions = computed(() => {
    const result: Question[] = [
      {
        id: 'fieldOfBusiness',
        title: 'fieldOfBusiness',
        type: 'radio',
        options: [
          {
            id: 'residential',
            label: 'residential',
            value: 'Residencial',
            illustration:
              '@/assets/images/quotation/residential/residential.svg',
          },
          {
            id: 'comercial',
            label: 'comercial',
            value: 'Comercial',
            illustration: '@/assets/images/quotation/comercial/comercial.svg',
          },
          {
            id: 'agroindustrial',
            label: 'agroindustrial',
            value: 'Agroindustrial',
            illustration:
              '@/assets/images/quotation/agroindustrial/agroindustrial.svg',
          },
          {
            id: 'industrial',
            label: 'industrial',
            value: 'Industrial',
            illustration: '@/assets/images/quotation/industrial/industrial.svg',
          },
        ],
        value: answers.fieldOfBusiness,
      },
    ]

    if (answers.fieldOfBusiness === 'Residencial') {
      result.push({
        id: 'residentialType',
        title: 'residentialType',
        type: 'radio',
        options: [
          {
            id: 'urban',
            label: 'urban',
            value: 'Urbano',
            illustration: '@/assets/images/quotation/residential/urban.svg',
          },
          {
            id: 'ruralCountry',
            label: 'ruralCountry',
            value: 'Rural/Campestre',
            illustration: '@/assets/images/quotation/residential/rural.svg',
          },
        ],
        value: answers.residentialType,
      })

      let rangeOfPeopleOptions: QuestionOption[] = []

      if (answers.residentialType === 'Urbano') {
        rangeOfPeopleOptions = [
          {
            id: '20To100',
            label: '20To100',
            value: '20 - 100',
            illustration: '@/assets/images/quotation/people.svg',
          },
          {
            id: '100To300',
            label: '100To300',
            value: '100 - 300',
            illustration: '@/assets/images/quotation/people.svg',
          },
          {
            id: '300To500',
            label: '300To500',
            value: '300 - 500',
            illustration: '@/assets/images/quotation/people.svg',
          },
        ]
      } else if (answers.residentialType === 'Rural/Campestre') {
        rangeOfPeopleOptions = [
          {
            id: '20To50',
            label: '20To50',
            value: '20 - 50',
            illustration: '@/assets/images/quotation/people.svg',
          },
          {
            id: '50To100',
            label: '50To100',
            value: '50 - 100',
            illustration: '@/assets/images/quotation/people.svg',
          },
          {
            id: '100To250',
            label: '100To250',
            value: '100 - 250',
            illustration: '@/assets/images/quotation/people.svg',
          },
        ]
      }

      result.push({
        id: 'rangeOfPeople',
        title: 'rangeOfPeople',
        type: 'radio',
        options: rangeOfPeopleOptions,
        value: answers.rangeOfPeople,
      })
    } else if (answers.fieldOfBusiness === 'Comercial') {
      result.push({
        id: 'commerceType',
        title: 'commerceType',
        type: 'radio',
        options: [
          {
            id: 'office',
            label: 'office',
            value: 'Oficina',
            illustration: '@/assets/images/quotation/comercial/office.svg',
          },
          {
            id: 'restaurant',
            label: 'restaurant',
            value: 'Restaurante',
            illustration: '@/assets/images/quotation/comercial/restaurant.svg',
          },
          {
            id: 'service',
            label: 'service',
            value: 'Servicio',
            illustration:
              '@/assets/images/quotation/comercial/service-comerce.svg',
          },
          {
            id: 'marketer',
            label: 'marketer',
            value: 'Comercializadora',
            illustration: '@/assets/images/quotation/comercial/marketer.svg',
          },
        ],
        value: answers.commerceType,
      })

      if (answers.commerceType === 'Oficina') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'originalWater',
              label: 'originalWater',
              value: 'Agua Original',
              illustration:
                '@/assets/images/quotation/comercial/original-water.svg',
            },
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })

        if (answers.waterType) {
          result.push({
            id: 'rangeOfPeople',
            title: 'rangeOfPeople',
            type: 'radio',
            options: [
              {
                id: '5To10',
                label: '5To10',
                value: '5 - 10',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '10To20',
                label: '10To20',
                value: '10 - 20',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '20To30',
                label: '20To30',
                value: '20 - 30',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '30To40',
                label: '30To40',
                value: '30 - 40',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '40To50',
                label: '40To50',
                value: '40 - 50',
                illustration: '@/assets/images/quotation/people.svg',
              },
            ],
            value: answers.rangeOfPeople,
          })
        }
      } else if (answers.commerceType === 'Restaurante') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'originalWater',
              label: 'originalWater',
              value: 'Agua Original',
              illustration:
                '@/assets/images/quotation/comercial/original-water.svg',
            },
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })
        if (answers.waterType) {
          result.push({
            id: 'rangeOfPeople',
            title: 'rangeOfPeople',
            type: 'radio',
            options: [
              {
                id: '50To100',
                label: '50To100',
                value: '50 - 100',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '100To200',
                label: '100To200',
                value: '100 - 200',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '200To250',
                label: '200To250',
                value: '200 - 250',
                illustration: '@/assets/images/quotation/people.svg',
              },
            ],
            value: answers.rangeOfPeople,
          })
        }
      } else if (answers.commerceType === 'Servicio') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'originalWater',
              label: 'originalWater',
              value: 'Agua Original',
              illustration:
                '@/assets/images/quotation/comercial/original-water.svg',
            },
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })
        if (
          answers.waterType === 'Agua Residual' &&
          answers.commerceType === 'Servicio'
        ) {
          result.push({
            id: 'rangeOfPeople',
            title: 'rangeOfPeople',
            type: 'radio',
            options: [
              {
                id: '1To5',
                label: '1To5',
                value: '1 - 5',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '5To10',
                label: '5To10',
                value: '5 - 10',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '10To20',
                label: '10To20',
                value: '10 - 20',
                illustration: '@/assets/images/quotation/people.svg',
              },
            ],
            value: answers.rangeOfPeople,
          })
        }
      } else if (answers.commerceType === 'Comercializadora') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'originalWater',
              label: 'originalWater',
              value: 'Agua Original',
              illustration:
                '@/assets/images/quotation/comercial/original-water.svg',
            },
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })
        if (answers.waterType) {
          result.push({
            id: 'rangeOfPeople',
            title: 'rangeOfPeople',
            type: 'radio',
            options: [
              {
                id: '50To100',
                label: '50To100',
                value: '50 - 100',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '100To200',
                label: '100To200',
                value: '100 - 200',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '200To250',
                label: '200To250',
                value: '200 - 250',
                illustration: '@/assets/images/quotation/people.svg',
              },
            ],
            value: answers.rangeOfPeople,
          })
        }
      }
    } else if (answers.fieldOfBusiness === 'Agroindustrial') {
      result.push({
        id: 'sectorType',
        title: 'sectorType',
        type: 'radio',
        options: [
          {
            id: 'agricultural',
            label: 'agricultural',
            value: 'Agrícola',
            illustration:
              '@/assets/images/quotation/agroindustrial/agricultural.svg',
          },
          {
            id: 'poultry',
            label: 'poultry',
            value: 'Avícola',
            illustration:
              '@/assets/images/quotation/agroindustrial/poultry.svg',
          },
          {
            id: 'swine',
            label: 'swine',
            value: 'Porcícola',
            illustration: '@/assets/images/quotation/agroindustrial/swine.svg',
          },
          {
            id: 'rastro',
            label: 'rastro',
            value: 'Rastro',
            illustration: '@/assets/images/quotation/agroindustrial/rastro.svg',
          },
        ],
        value: answers.sectorType,
      })
      if (answers.sectorType === 'Agrícola') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })
        if (answers.waterType) {
          result.push({
            id: 'rangeOfPeople',
            title: 'rangeOfPeople',
            type: 'radio',
            options: [
              {
                id: '250To500',
                label: '250To500',
                value: '250 - 500',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '500To750',
                label: '500To750',
                value: '500 - 750',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '750To1000',
                label: '750To1000',
                value: '750 - 1,000',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '1000To1500',
                label: '1000To1500',
                value: '1,000 - 1,500',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '1500To2500',
                label: '1500To2500',
                value: '1,500 - 2,500',
                illustration: '@/assets/images/quotation/people.svg',
              },
            ],
            value: answers.rangeOfPeople,
          })
        }
      } else if (answers.sectorType === 'Avícola') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'originalWater',
              label: 'originalWater',
              value: 'Agua Original',
              illustration:
                '@/assets/images/quotation/comercial/original-water.svg',
            },
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })
        if (answers.waterType === 'Agua Original') {
          result.push({
            id: 'typeOfBird',
            title: 'typeOfBird',
            type: 'radio',
            options: [
              {
                id: 'breeding',
                label: 'breeding',
                value: 'Crianza',
                illustration:
                  '@/assets/images/quotation/agroindustrial/breeding.svg',
              },
              {
                id: 'postura',
                label: 'postura',
                value: 'Postura',
                illustration:
                  '@/assets/images/quotation/agroindustrial/poultry.svg',
              },
            ],
            value: answers.typeOfBird,
          })
          if (answers.typeOfBird === 'Crianza') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '0To100000',
                  label: '0To100000',
                  value: '0 - 100,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/breeding.svg',
                },
                {
                  id: '100000To200000',
                  label: '100000To200000',
                  value: '100,000 - 200,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/breeding.svg',
                },
                {
                  id: '200000To300000',
                  label: '200000To300000',
                  value: '200,000 - 300,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/breeding.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.typeOfBird === 'Postura') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '0To100000',
                  label: '0To100000',
                  value: '0 - 100,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/poultry.svg',
                },
                {
                  id: '100000To200000',
                  label: '100000To200000',
                  value: '100,000 - 200,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/poultry.svg',
                },
                {
                  id: '200000To300000',
                  label: '200000To300000',
                  value: '200,000 - 300,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/poultry.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          }
        } else if (answers.waterType === 'Agua Residual') {
          result.push({
            id: 'residualWaterToBeTreated',
            title: 'residualWaterToBeTreated',
            type: 'radio',
            options: [
              {
                id: 'serviceWater',
                label: 'serviceWater',
                value: 'Agua de Servicio',
                illustration: '@/assets/images/quotation/service.svg',
              },
              {
                id: 'productionResidualWater',
                label: 'productionResidualWater',
                value: 'Agua Residual de Producción',
                illustration:
                  '@/assets/images/quotation/comercial/residual-water.svg',
              },
            ],
            value: answers.residualWaterToBeTreated,
          })
        }
        if (answers.residualWaterToBeTreated === 'Agua de Servicio') {
          result.push({
            id: 'rangeOfPeople',
            title: 'rangeOfPeople',
            type: 'radio',
            options: [
              {
                id: '20To50',
                label: '20To50',
                value: '20 - 50',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '50To100',
                label: '50To100',
                value: '50 - 100',
                illustration: '@/assets/images/quotation/people.svg',
              },
              {
                id: '100To150',
                label: '100To150',
                value: '100 - 150',
                illustration: '@/assets/images/quotation/people.svg',
              },
            ],
            value: answers.rangeOfPeople,
          })
        } else if (
          answers.residualWaterToBeTreated === 'Agua Residual de Producción'
        ) {
          result.push({
            id: 'tonsOfChickensPerDay',
            title: 'tonsOfChickensPerDay',
            type: 'radio',
            options: [
              {
                id: '0To40',
                label: '0To40',
                value: '0 - 40',
                illustration:
                  '@/assets/images/quotation/agroindustrial/poultry.svg',
              },
              {
                id: '40To80',
                label: '40To80',
                value: '40 - 80',
                illustration:
                  '@/assets/images/quotation/agroindustrial/poultry.svg',
              },
              {
                id: '80To120',
                label: '80To120',
                value: '80 - 120',
                illustration:
                  '@/assets/images/quotation/agroindustrial/poultry.svg',
              },
            ],
            value: answers.tonsOfChickensPerDay,
          })
        }
      } else if (answers.sectorType === 'Porcícola') {
        result.push({
          id: 'waterType',
          title: 'waterType',
          type: 'radio',
          options: [
            {
              id: 'originalWater',
              label: 'originalWater',
              value: 'Agua Original',
              illustration:
                '@/assets/images/quotation/comercial/original-water.svg',
            },
            {
              id: 'residualWater',
              label: 'residualWater',
              value: 'Agua Residual',
              illustration:
                '@/assets/images/quotation/comercial/residual-water.svg',
            },
          ],
          value: answers.waterType,
        })
        if (answers.waterType === 'Agua Original') {
          result.push({
            id: 'siteType',
            title: 'siteType',
            type: 'radio',
            options: [
              {
                id: 'site1',
                label: 'site1',
                value: 'Sitio 1',
                illustration:
                  '@/assets/images/quotation/agroindustrial/swine-site1.svg',
              },
              {
                id: 'site2',
                label: 'site2',
                value: 'Sitio 2',
                illustration:
                  '@/assets/images/quotation/agroindustrial/swine-site2.svg',
              },
              {
                id: 'site3',
                label: 'site3',
                value: 'Sitio 3',
                illustration:
                  '@/assets/images/quotation/agroindustrial/swine-site3.svg',
              },
              {
                id: 'site2-3',
                label: 'site2-3',
                value: 'Sitio 2-3',
                illustration:
                  '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
              },
            ],
            value: answers.siteType,
          })
          if (answers.siteType === 'Sitio 1') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '50To2500',
                  label: '50To2500',
                  value: '50 - 2,500',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
                {
                  id: '2500To5000',
                  label: '2500To5000',
                  value: '2,500 - 5,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
                {
                  id: '5000To10000',
                  label: '5000To10000',
                  value: '5,000 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.siteType === 'Sitio 2') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '50To10000',
                  label: '50To10000',
                  value: '50 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
                {
                  id: '10000To20000',
                  label: '10000To20000',
                  value: '10,000 - 20,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
                {
                  id: '20000To30000',
                  label: '20000To30000',
                  value: '20,000 - 30,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.siteType === 'Sitio 3') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '50To10000',
                  label: '50To10000',
                  value: '50 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
                {
                  id: '10000To20000',
                  label: '10000To20000',
                  value: '10,000 - 20,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
                {
                  id: '20000To30000',
                  label: '20000To30000',
                  value: '20,000 - 30,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.siteType === 'Sitio 2-3') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '50To10000',
                  label: '50To10000',
                  value: '50 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
                {
                  id: '10000To20000',
                  label: '10000To20000',
                  value: '10,000 - 20,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
                {
                  id: '20000To30000',
                  label: '20000To30000',
                  value: '20,000 - 30,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          }
        } else if (answers.waterType === 'Agua Residual') {
          result.push({
            id: 'residualWaterToBeTreated',
            title: 'residualWaterToBeTreated',
            type: 'radio',
            options: [
              {
                id: 'serviceWater',
                label: 'serviceWater',
                value: 'Agua de Servicio',
                illustration: '@/assets/images/quotation/service.svg',
              },
              {
                id: 'productionResidualWater',
                label: 'productionResidualWater',
                value: 'Agua Residual de Producción',
                illustration:
                  '@/assets/images/quotation/comercial/residual-water.svg',
              },
            ],
            value: answers.residualWaterToBeTreated,
          })
          if (answers.residualWaterToBeTreated === 'Agua de Servicio') {
            result.push({
              id: 'rangeOfPeople',
              title: 'rangeOfPeople',
              type: 'radio',
              options: [
                {
                  id: '25To50',
                  label: '25To50',
                  value: '25 - 50',
                  illustration: '@/assets/images/quotation/people.svg',
                },
                {
                  id: '50To100',
                  label: '50To100',
                  value: '50 - 100',
                  illustration: '@/assets/images/quotation/people.svg',
                },
                {
                  id: '100To150',
                  label: '100To150',
                  value: '100 - 150',
                  illustration: '@/assets/images/quotation/people.svg',
                },
              ],
              value: answers.rangeOfPeople,
            })
          } else if (
            answers.residualWaterToBeTreated === 'Agua Residual de Producción'
          ) {
            result.push({
              id: 'siteType',
              title: 'siteType',
              type: 'radio',
              options: [
                {
                  id: 'site1',
                  label: 'site1',
                  value: 'Sitio 1',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
                {
                  id: 'site2',
                  label: 'site2',
                  value: 'Sitio 2',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
                {
                  id: 'site3',
                  label: 'site3',
                  value: 'Sitio 3',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
                {
                  id: 'site2-3',
                  label: 'site2-3',
                  value: 'Sitio 2-3',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
              ],
              value: answers.siteType,
            })
          }
          if (answers.siteType === 'Sitio 1') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '50To2500',
                  label: '50To2500',
                  value: '50 - 2,500',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
                {
                  id: '2500To5000',
                  label: '2500To5000',
                  value: '2,500 - 5,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
                {
                  id: '5000To10000',
                  label: '5000To10000',
                  value: '5,000 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site1.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.siteType === 'Sitio 2') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '1000To10000',
                  label: '1000To10000',
                  value: '1,000 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
                {
                  id: '10000To20000',
                  label: '10000To20000',
                  value: '10,000 - 20,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
                {
                  id: '20000To30000',
                  label: '20000To30000',
                  value: '20,000 - 30,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.siteType === 'Sitio 3') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '1000To10000',
                  label: '1000To10000',
                  value: '1,000 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
                {
                  id: '10000To20000',
                  label: '10000To20000',
                  value: '10,000 - 20,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
                {
                  id: '20000To30000',
                  label: '20000To30000',
                  value: '20,000 - 30,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site3.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          } else if (answers.siteType === 'Sitio 2-3') {
            result.push({
              id: 'animalQuantity',
              title: 'animalQuantity',
              type: 'radio',
              options: [
                {
                  id: '1000To10000',
                  label: '1000To10000',
                  value: '1,000 - 10,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
                {
                  id: '10000To20000',
                  label: '10000To20000',
                  value: '10,000 - 20,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
                {
                  id: '20000To30000',
                  label: '20000To30000',
                  value: '20,000 - 30,000',
                  illustration:
                    '@/assets/images/quotation/agroindustrial/swine-site2-3.svg',
                },
              ],
              value: answers.animalQuantity,
            })
          }
        }
      } else if (answers.sectorType === 'Rastro') {
        result.push({
          id: 'animalType',
          title: 'animalType',
          type: 'radio',
          options: [
            {
              id: 'beef',
              label: 'beef',
              value: 'Res',
              illustration: '@/assets/images/quotation/agroindustrial/cow.svg',
            },
            {
              id: 'pork',
              label: 'pork',
              value: 'Cerdo',
              illustration:
                '@/assets/images/quotation/agroindustrial/swine.svg',
            },
            {
              id: 'bird',
              label: 'bird',
              value: 'Ave',
              illustration:
                '@/assets/images/quotation/agroindustrial/poultry.svg',
            },
          ],
          value: answers.animalType,
        })
        let currentAnimalImage = ''
        if (answers.animalType == 'Res') {
          currentAnimalImage = 'cow'
        } else if (answers.animalType == 'Cerdo') {
          currentAnimalImage = 'swine'
        } else if (answers.animalType == 'Ave') {
          currentAnimalImage = 'poultry'
        }

        if (answers.animalType) {
          result.push({
            id: 'animalQuantity',
            title: 'animalQuantity',
            type: 'radio',
            options: [
              {
                id: '100To300',
                label: '100To300',
                value: '100 - 300',
                illustration: `@/assets/images/quotation/agroindustrial/${currentAnimalImage}.svg`,
              },
              {
                id: '300To500',
                label: '300To500',
                value: '300 - 500',
                illustration: `@/assets/images/quotation/agroindustrial/${currentAnimalImage}.svg`,
              },
              {
                id: '500To800',
                label: '500To800',
                value: '500 - 800',
                illustration: `@/assets/images/quotation/agroindustrial/${currentAnimalImage}.svg`,
              },
            ],
            value: answers.animalQuantity,
          })
        }
      }
    } else if (answers.fieldOfBusiness === 'Industrial') {
      result.push({
        id: 'typeOfWaterToBeTreated',
        title: 'typeOfWaterToBeTreated',
        type: 'radio',
        options: [
          {
            id: 'service',
            label: 'service',
            value: 'Servicio',
            illustration: '@/assets/images/quotation/service.svg',
          },
          {
            id: 'process',
            label: 'process',
            value: 'Proceso',
            illustration: '@/assets/images/quotation/industrial/industrial.svg',
          },
        ],
        value: answers.typeOfWaterToBeTreated,
      })
      if (answers.typeOfWaterToBeTreated === 'Servicio') {
        result.push({
          id: 'numberOfShifts',
          title: 'numberOfShifts',
          type: 'radio',
          options: [
            {
              id: 'turn1',
              label: 'turn1',
              value: 'Turno 1',
              illustration: '@/assets/images/quotation/people.svg',
            },
            {
              id: 'turn2',
              label: 'turn2',
              value: 'Turno 2',
              illustration: '@/assets/images/quotation/people.svg',
            },
          ],
          value: answers.numberOfShifts,
        })
      }
      if (answers.numberOfShifts) {
        result.push({
          id: 'shiftEmployerAmount',
          title: 'shiftEmployerAmount',
          type: 'radio',
          options: [
            {
              id: '10To100',
              label: '10To100',
              value: '10 - 100',
              illustration: '@/assets/images/quotation/people.svg',
            },
            {
              id: '100To500',
              label: '100To500',
              value: '100 - 500',
              illustration: '@/assets/images/quotation/people.svg',
            },
            {
              id: '500To1000',
              label: '500To1000',
              value: '500 - 1,000',
              illustration: '@/assets/images/quotation/people.svg',
            },
            {
              id: '1000To2000',
              label: '1000To2000',
              value: '1,000 - 2,000',
              illustration: '@/assets/images/quotation/people.svg',
            },
          ],
          value: answers.shiftEmployerAmount,
        })
      }
    }
    return result
  })

  const results = computed(() => {
    let records: Record<string, Result> = {}

    if (answers.fieldOfBusiness === 'Residencial') {
      records = {
        proposedTechnology: {
          id: 'proposedTechnology',
          title: 'proposedTechnology',
          value: null,
        },
        wasteWaterFlowToBeTreated: {
          id: 'wasteWaterFlowToBeTreated',
          title: 'wasteWaterFlowToBeTreated',
          value: null,
          unit: 'm3',
        },
        biochemicalOxygenDemandConcentration: {
          id: 'biochemicalOxygenDemandConcentration',
          title: 'biochemicalOxygenDemandConcentration',
          value: null,
          unit: 'mg/L',
        },
        complianceWithTheStandard: {
          id: 'complianceWithTheStandard',
          title: 'complianceWithTheStandard',
          value: null,
        },
        requiredArea: {
          id: 'requiredArea',
          title: 'requiredArea',
          value: null,
          unit: 'm2',
        },
        investmentBaseBudget: {
          id: 'investmentBaseBudget',
          title: 'investmentBaseBudget',
          value: null,
        },
      }

      if (answers.residentialType === 'Urbano') {
        if (answers.rangeOfPeople === '20 - 100') {
          records.proposedTechnology.value = 'MBBR'
          records.wasteWaterFlowToBeTreated.value = '4.4-22'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.complianceWithTheStandard.value = 'NOM 003'
          records.requiredArea.value = '7.5'
          records.investmentBaseBudget.value = '$1,100,000 - $1,300,000 M.N.'
        } else if (answers.rangeOfPeople === '100 - 300') {
          records.proposedTechnology.value = 'MBBR'
          records.wasteWaterFlowToBeTreated.value = '22-66'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.complianceWithTheStandard.value = 'NOM 003'
          records.requiredArea.value = '10.5'
          records.investmentBaseBudget.value = '$2,000,000 - $2,500,000 M.N.'
        } else if (answers.rangeOfPeople === '300 - 500') {
          records.proposedTechnology.value = 'MBBR'
          records.wasteWaterFlowToBeTreated.value = '66-110'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.complianceWithTheStandard.value = 'NOM 003'
          records.requiredArea.value = '12.5'
          records.investmentBaseBudget.value = '$2,800,000 - $3,500,000 M.N.'
        }
      } else if (answers.residentialType === 'Rural/Campestre') {
        if (answers.rangeOfPeople === '20 - 50') {
          records.proposedTechnology.value = 'MBBR'
          records.wasteWaterFlowToBeTreated.value = '6-30'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.complianceWithTheStandard.value = 'NOM 003'
          records.requiredArea.value = '9'
          records.investmentBaseBudget.value = '$1,300,000 - $1,600,000 M.N.'
        } else if (answers.rangeOfPeople === '50 - 100') {
          records.proposedTechnology.value = 'MBBR'
          records.wasteWaterFlowToBeTreated.value = '30-90'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.complianceWithTheStandard.value = 'NOM 003'
          records.requiredArea.value = '10.5'
          records.investmentBaseBudget.value = '$2,800,000 - $3,400,000 M.N.'
        } else if (answers.rangeOfPeople === '100 - 250') {
          records.proposedTechnology.value = 'MBBR'
          records.wasteWaterFlowToBeTreated.value = '90-150'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.complianceWithTheStandard.value = 'NOM 003'
          records.requiredArea.value = '19.5'
          records.investmentBaseBudget.value = '$3,700,000 - $4,600,000 M.N.'
        }
      }
    }

    if (answers.fieldOfBusiness === 'Comercial') {
      if (
        answers.waterType === 'Agua Original' &&
        answers.commerceType === 'Comercializadora'
      ) {
        records = {
          waterFlowToBeTreatedPerDay: {
            id: 'waterFlowToBeTreatedPerDay',
            title: 'waterFlowToBeTreatedPerDay',
            value: null,
            unit: 'm3',
          },
          contactCompany: {
            id: 'contactCompany',
            title: 'contactCompany',
            value: null,
          },
        }
      } else if (
        answers.waterType === 'Agua Original' &&
        answers.commerceType === 'Servicio'
      ) {
        records = {
          contactCompany: {
            id: 'contactCompany',
            title: 'contactCompany',
            value:
              'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.',
          },
        }
      } else if (answers.waterType === 'Agua Original') {
        records = {
          waterFlowToBeTreatedPerDay: {
            id: 'waterFlowToBeTreatedPerDay',
            title: 'waterFlowToBeTreatedPerDay',
            value: null,
            unit: 'm3',
          },
          contactCompany: {
            id: 'contactCompany',
            title: 'contactCompany',
            value:
              'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.',
          },
        }
      } else if (answers.waterType === 'Agua Residual') {
        records = {
          proposedTechnology: {
            id: 'proposedTechnology',
            title: 'proposedTechnology',
            value: null,
          },
          waterFlowToBeTreatedPerDay: {
            id: 'waterFlowToBeTreatedPerDay',
            title: 'waterFlowToBeTreatedPerDay',
            value: null,
            unit: 'm3',
          },
          biochemicalOxygenDemandConcentration: {
            id: 'biochemicalOxygenDemandConcentration',
            title: 'biochemicalOxygenDemandConcentration',
            value: null,
            unit: 'mg/L',
          },
          requiredArea: {
            id: 'requiredArea',
            title: 'requiredArea',
            value: null,
            unit: 'm2',
          },
          complianceWithStandard: {
            id: 'complianceWithStandard',
            title: 'complianceWithStandard',
            value: null,
          },
          investmentBaseBudget: {
            id: 'investmentBaseBudget',
            title: 'investmentBaseBudget',
            value: null,
          },
        }
      }
      if (
        answers.waterType === 'Agua Residual' &&
        answers.commerceType === 'Servicio'
      ) {
        records = {
          proposedTechnology: {
            id: 'proposedTechnology',
            title: 'proposedTechnology',
            value: null,
          },
          waterFlowToBeTreatedPerDay: {
            id: 'waterFlowToBeTreatedPerDay',
            title: 'waterFlowToBeTreatedPerDay',
            value: null,
            unit: 'm3',
          },
          biochemicalOxygenDemandConcentration: {
            id: 'biochemicalOxygenDemandConcentration',
            title: 'biochemicalOxygenDemandConcentration',
            value: null,
            unit: 'mg/L',
          },
          requiredArea: {
            id: 'requiredArea',
            title: 'requiredArea',
            value: null,
            unit: 'm2',
          },
          investmentBaseBudget: {
            id: 'investmentBaseBudget',
            title: 'investmentBaseBudget',
            value: null,
          },
        }
      }
      if (
        answers.waterType === 'Agua Original' &&
        answers.commerceType === 'Oficina'
      ) {
        if (answers.rangeOfPeople === '5 - 10') {
          records.waterFlowToBeTreatedPerDay.value = '0-1'
        } else if (answers.rangeOfPeople === '10 - 20') {
          records.waterFlowToBeTreatedPerDay.value = '1-1.5'
        } else if (answers.rangeOfPeople === '20 - 30') {
          records.waterFlowToBeTreatedPerDay.value = '1.5-2.1'
        } else if (answers.rangeOfPeople === '30 - 40') {
          records.waterFlowToBeTreatedPerDay.value = '2.1-2.8'
        } else if (answers.rangeOfPeople === '40 - 50') {
          records.waterFlowToBeTreatedPerDay.value = '2.8-3.5'
        }
      } else if (
        answers.waterType === 'Agua Residual' &&
        answers.commerceType === 'Oficina'
      ) {
        if (answers.rangeOfPeople === '5 - 10') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '0.35-0.7'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$400,000 - $500,000 M.N.'
        } else if (answers.rangeOfPeople === '10 - 20') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '0.7-1.4'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$450,000 - $550,000 M.N.'
        } else if (answers.rangeOfPeople === '20 - 30') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '1.4-2.1'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1.4'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$450,000 - $550,000 M.N.'
        } else if (answers.rangeOfPeople === '30 - 40') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '2.1-2.8'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1.4'
          records.investmentBaseBudget.value = '$500,000 - $600,000 M.N.'
          records.complianceWithStandard.value = 'NOM 003'
        } else if (answers.rangeOfPeople === '40 - 50') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '2.8-3.5'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1.4'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$500,000 - $600,000 M.N.'
        }
      } else if (
        answers.waterType === 'Agua Original' &&
        answers.commerceType === 'Restaurante'
      ) {
        if (answers.rangeOfPeople === '50 - 100') {
          records.waterFlowToBeTreatedPerDay.value = '1.5'
        } else if (answers.rangeOfPeople === '100 - 200') {
          records.waterFlowToBeTreatedPerDay.value = '5-10'
        } else if (answers.rangeOfPeople === '200 - 250') {
          records.waterFlowToBeTreatedPerDay.value = '10-12.5'
        }
      } else if (
        answers.waterType === 'Agua Residual' &&
        answers.commerceType === 'Restaurante'
      ) {
        if (answers.rangeOfPeople === '50 - 100') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '1-5'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '2'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$500,000 - $600,000 M.N.'
        } else if (answers.rangeOfPeople === '100 - 200') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '5-10'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '4'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$600,000 - $800,000 M.N.'
        } else if (answers.rangeOfPeople === '200 - 250') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '10-12.5'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '5'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$800,000 - $1,000,000 M.N.'
        }
      } else if (
        answers.waterType === 'Agua Original' &&
        answers.commerceType === 'Servicio'
      ) {
        records.contactCompany.value =
          'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.'
      } else if (
        answers.waterType === 'Agua Residual' &&
        answers.commerceType === 'Servicio'
      ) {
        if (answers.rangeOfPeople === '1 - 5') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '0.5'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1'
          records.investmentBaseBudget.value = '$300,000 - $500,000 M.N.'
        } else if (answers.rangeOfPeople === '5 - 10') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '0.5-0.7'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1'
          records.investmentBaseBudget.value = '$400,000 - $500,000 M.N.'
        } else if (answers.rangeOfPeople === '10 - 20') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '0.7-1.4'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1'
          records.investmentBaseBudget.value = '$450,000 - $550,000 M.N.'
        }
      } else if (
        answers.waterType === 'Agua Original' &&
        answers.commerceType === 'Comercializadora'
      ) {
        if (answers.rangeOfPeople === '50 - 100') {
          records.waterFlowToBeTreatedPerDay.value = '3.7-7'
          records.contactCompany.value =
            'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.'
        } else if (answers.rangeOfPeople === '100 - 200') {
          records.waterFlowToBeTreatedPerDay.value = '7-14'
          records.contactCompany.value =
            'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.'
        } else if (answers.rangeOfPeople === '200 - 250') {
          records.waterFlowToBeTreatedPerDay.value = '14-17.5'
          records.contactCompany.value =
            'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.'
        }
      } else if (
        answers.waterType === 'Agua Residual' &&
        answers.commerceType === 'Comercializadora'
      ) {
        if (answers.rangeOfPeople === '50 - 100') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '3.5-7'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '4'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$600,000 - $800,000 M.N.'
        } else if (answers.rangeOfPeople === '100 - 200') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '7-14'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '5'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$800,000 - $1,000,000 M.N.'
        } else if (answers.rangeOfPeople === '200 - 250') {
          records.proposedTechnology.value = 'MBBR'
          records.waterFlowToBeTreatedPerDay.value = '14-17.5'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '5.5'
          records.complianceWithStandard.value = 'NOM 003'
          records.investmentBaseBudget.value = '$1,000,000 - $1,300,000 M.N.'
        }
      }
    } else if (answers.fieldOfBusiness === 'Agroindustrial') {
      if (answers.waterType === 'Agua Original') {
        records = {
          waterConsumptionPerDay: {
            id: 'waterConsumptionPerDay',
            title: 'waterConsumptionPerDay',
            value: null,
            unit: 'm3',
          },
          contactCompany: {
            id: 'contactCompany',
            title: 'contactCompany',
            value:
              'Para la cotización de un sistema de tratamiento de agua original se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.',
          },
        }
      } else if (answers.waterType === 'Agua Residual') {
        records = {
          proposedTechnology: {
            id: 'proposedTechnology',
            title: 'proposedTechnology',
            value: null,
          },
          waterFlowToBeTreatedPerDay: {
            id: 'waterFlowToBeTreatedPerDay',
            title: 'waterFlowToBeTreatedPerDay',
            value: null,
            unit: 'm3',
          },
          biochemicalOxygenDemandConcentration: {
            id: 'biochemicalOxygenDemandConcentration',
            title: 'biochemicalOxygenDemandConcentration',
            value: null,
            unit: 'mg/L',
          },
          requiredArea: {
            id: 'requiredArea',
            title: 'requiredArea',
            value: null,
            unit: 'm2',
          },
          complianceWithStandard: {
            id: 'complianceWithStandard',
            title: 'complianceWithStandard',
            value: null,
          },
          investmentBaseBudget: {
            id: 'investmentBaseBudget',
            title: 'investmentBaseBudget',
            value: null,
          },
        }
      }
      if (
        answers.residualWaterToBeTreated === 'Agua Residual de Producción' ||
        answers.sectorType === 'Rastro'
      ) {
        records = {
          proposedTechnology: {
            id: 'proposedTechnology',
            title: 'proposedTechnology',
            value: null,
          },
          waterFlowToBeTreatedPerDay: {
            id: 'waterFlowToBeTreatedPerDay',
            title: 'waterFlowToBeTreatedPerDay',
            value: null,
            unit: 'm3',
          },
          biochemicalOxygenDemandConcentration: {
            id: 'biochemicalOxygenDemandConcentration',
            title: 'biochemicalOxygenDemandConcentration',
            value: null,
            unit: 'mg/L',
          },
          requiredArea: {
            id: 'requiredArea',
            title: 'requiredArea',
            value: null,
            unit: 'm2',
          },
          complianceWithStandard: {
            id: 'complianceWithStandard',
            title: 'complianceWithStandard',
            value: null,
          },
        }
        if (
          answers.residualWaterToBeTreated === 'Agua Residual de Producción' &&
          answers.sectorType === 'Avícola'
        ) {
          records = {
            tonsOfChickensPerDay: {
              id: 'tonsOfChickensPerDay',
              title: 'tonsOfChickensPerDay',
              value: null,
            },
            proposedTechnology: {
              id: 'proposedTechnology',
              title: 'proposedTechnology',
              value: null,
            },
            waterFlowToBeTreatedPerDay: {
              id: 'waterFlowToBeTreatedPerDay',
              title: 'waterFlowToBeTreatedPerDay',
              value: null,
              unit: 'm3',
            },
            biochemicalOxygenDemandConcentration: {
              id: 'biochemicalOxygenDemandConcentration',
              title: 'biochemicalOxygenDemandConcentration',
              value: null,
              unit: 'mg/L',
            },
            requiredArea: {
              id: 'requiredArea',
              title: 'requiredArea',
              value: null,
              unit: 'm2',
            },
            investmentBaseBudget: {
              id: 'investmentBaseBudget',
              title: 'investmentBaseBudget',
              value: null,
            },
          }
        }
        if (
          answers.residualWaterToBeTreated === 'Agua Residual de Producción' &&
          answers.sectorType === 'Porcícola'
        ) {
          records = {
            proposedTechnology: {
              id: 'proposedTechnology',
              title: 'proposedTechnology',
              value: null,
            },
            waterFlowToBeTreatedPerDay: {
              id: 'waterFlowToBeTreatedPerDay',
              title: 'waterFlowToBeTreatedPerDay',
              value: null,
              unit: 'm3',
            },
            biochemicalOxygenDemandConcentration: {
              id: 'biochemicalOxygenDemandConcentration',
              title: 'biochemicalOxygenDemandConcentration',
              value: null,
              unit: 'mg/L',
            },
            requiredArea: {
              id: 'requiredArea',
              title: 'requiredArea',
              value: null,
              unit: 'm2',
            },
            investmentBaseBudget: {
              id: 'investmentBaseBudget',
              title: 'investmentBaseBudget',
              value: null,
            },
          }
        }

        if (
          answers.animalType === 'Res' ||
          answers.animalType === 'Cerdo' ||
          answers.animalType === 'Ave'
        ) {
          records = {
            proposedTechnology: {
              id: 'proposedTechnology',
              title: 'proposedTechnology',
              value: null,
            },
            waterFlowToBeTreatedPerDay: {
              id: 'waterFlowToBeTreatedPerDay',
              title: 'waterFlowToBeTreatedPerDay',
              value: null,
              unit: 'm3',
            },
            biochemicalOxygenDemandConcentration: {
              id: 'biochemicalOxygenDemandConcentration',
              title: 'biochemicalOxygenDemandConcentration',
              value: null,
              unit: 'mg/L',
            },
            requiredArea: {
              id: 'requiredArea',
              title: 'requiredArea',
              value: null,
              unit: 'm2',
            },
            investmentBaseBudget: {
              id: 'investmentBaseBudget',
              title: 'investmentBaseBudget',
              value: null,
            },
          }
        }
      }
      if (
        answers.sectorType === 'Agrícola' &&
        answers.waterType === 'Agua Residual'
      ) {
        if (answers.rangeOfPeople === '250 - 500') {
          records.proposedTechnology.value = 'Sistema Lagunar'
          records.waterFlowToBeTreatedPerDay.value = '62.5-125'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1,230'
          records.complianceWithStandard.value = 'NOM 001'
          records.investmentBaseBudget.value = '$850,000 - $1,100,000 M.N.'
        } else if (answers.rangeOfPeople === '500 - 750') {
          records.proposedTechnology.value = 'Sistema Lagunar'
          records.waterFlowToBeTreatedPerDay.value = '125-190'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1,570'
          records.complianceWithStandard.value = 'NOM 001'
          records.investmentBaseBudget.value = '$1,200,000 - $1,600,000 M.N.'
        } else if (answers.rangeOfPeople === '750 - 1,000') {
          records.proposedTechnology.value = 'Sistema Lagunar'
          records.waterFlowToBeTreatedPerDay.value = '190-250'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '1,930'
          records.complianceWithStandard.value = 'NOM 001'
          records.investmentBaseBudget.value = '$1,700,000 - $2,200,000 M.N.'
        } else if (answers.rangeOfPeople === '1,000 - 1,500') {
          records.proposedTechnology.value = 'Sistema Lagunar'
          records.waterFlowToBeTreatedPerDay.value = '250-375'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '2,640'
          records.complianceWithStandard.value = 'NOM 001'
          records.investmentBaseBudget.value = '$2,500,000 - $3,100,000 M.N.'
        } else if (answers.rangeOfPeople === '1,500 - 2,500') {
          records.proposedTechnology.value = 'Sistema Lagunar'
          records.waterFlowToBeTreatedPerDay.value = '375-625'
          records.biochemicalOxygenDemandConcentration.value = '300 – 600'
          records.requiredArea.value = '3,930'
          records.complianceWithStandard.value = 'NOM 001'
          records.investmentBaseBudget.value = '$3,500,000 - $4,500,000 M.N.'
        }
      }
      if (answers.waterType === 'Agua Original') {
        if (answers.typeOfBird === 'Crianza') {
          if (answers.animalQuantity === '0 - 100,000') {
            records.waterConsumptionPerDay.value = '10-40'
          } else if (answers.animalQuantity === '100,000 - 200,000') {
            records.waterConsumptionPerDay.value = '40-70'
          } else if (answers.animalQuantity === '200,000 - 300,000') {
            records.waterConsumptionPerDay.value = '70-100'
          }
        } else if (answers.typeOfBird === 'Postura') {
          if (answers.animalQuantity === '0 - 100,000') {
            records.waterConsumptionPerDay.value = '10-40'
          } else if (answers.animalQuantity === '100,000 - 200,000') {
            records.waterConsumptionPerDay.value = '40-70'
          } else if (answers.animalQuantity === '200,000 - 300,000') {
            records.waterConsumptionPerDay.value = '70-100'
          }
        }
      } else if (answers.waterType === 'Agua Residual') {
        if (answers.residualWaterToBeTreated === 'Agua de Servicio') {
          if (answers.rangeOfPeople === '20 - 50') {
            records.proposedTechnology.value = 'Sistema Lagunar'
            records.waterFlowToBeTreatedPerDay.value = '4-10'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '280'
            records.complianceWithStandard.value = 'NOM 001'
            records.investmentBaseBudget.value = '$600,000 - $700,000 M.N.'
          } else if (answers.rangeOfPeople === '50 - 100') {
            records.proposedTechnology.value = 'Sistema Lagunar'
            records.waterFlowToBeTreatedPerDay.value = '10-20'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '410'
            records.complianceWithStandard.value = 'NOM 001'
            records.investmentBaseBudget.value = '$650,000 - $800,000 M.N.'
          } else if (answers.rangeOfPeople === '100 - 150') {
            records.proposedTechnology.value = 'Sistema Lagunar'
            records.waterFlowToBeTreatedPerDay.value = '20-30'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '500'
            records.complianceWithStandard.value = 'NOM 001'
            records.investmentBaseBudget.value = '$800,000 - $900,000 M.N.'
          }
        } else if (
          answers.residualWaterToBeTreated === 'Agua Residual de Producción'
        ) {
          if (answers.tonsOfChickensPerDay === '0 - 40') {
            records.tonsOfChickensPerDay.value = '0-40 ton'
            records.proposedTechnology.value = 'Biorreactor Anaerobio'
            records.waterFlowToBeTreatedPerDay.value = '1-40'
            records.biochemicalOxygenDemandConcentration.value = '879'
            records.requiredArea.value = '340'
            records.investmentBaseBudget.value =
              '$13,500,000 - $14,500,000 M.N.'
          } else if (answers.tonsOfChickensPerDay === '40 - 80') {
            records.tonsOfChickensPerDay.value = '40-80 ton'
            records.proposedTechnology.value = 'Biorreactor Anaerobio'
            records.waterFlowToBeTreatedPerDay.value = '40-80'
            records.biochemicalOxygenDemandConcentration.value = '879'
            records.requiredArea.value = '510'
            records.investmentBaseBudget.value =
              '$14,500,000 - $16,000,000 M.N.'
          } else if (answers.tonsOfChickensPerDay === '80 - 120') {
            records.tonsOfChickensPerDay.value = '80-120 ton'
            records.proposedTechnology.value = 'Biorreactor Anaerobio'
            records.waterFlowToBeTreatedPerDay.value = '80-120'
            records.biochemicalOxygenDemandConcentration.value = '879'
            records.requiredArea.value = '681'
            records.investmentBaseBudget.value =
              '$16,000,000 - $19,500,000 M.N.'
          }
        }
      }
      if (answers.sectorType === 'Porcícola') {
        if (answers.waterType === 'Agua Original') {
          if (answers.siteType === 'Sitio 1') {
            if (answers.animalQuantity === '50 - 2,500') {
              records.waterConsumptionPerDay.value = '1-50'
            } else if (answers.animalQuantity === '2,500 - 5,000') {
              records.waterConsumptionPerDay.value = '50-100'
            } else if (answers.animalQuantity === '5,000 - 10,000') {
              records.waterConsumptionPerDay.value = '100-200'
            }
          } else if (answers.siteType === 'Sitio 2') {
            if (answers.animalQuantity === '50 - 10,000') {
              records.waterConsumptionPerDay.value = '1-25'
            } else if (answers.animalQuantity === '10,000 - 20,000') {
              records.waterConsumptionPerDay.value = '25-50'
            } else if (answers.animalQuantity === '20,000 - 30,000') {
              records.waterConsumptionPerDay.value = '50-75'
            }
          } else if (answers.siteType === 'Sitio 3') {
            if (answers.animalQuantity === '50 - 10,000') {
              records.waterConsumptionPerDay.value = '1-90'
            } else if (answers.animalQuantity === '10,000 - 20,000') {
              records.waterConsumptionPerDay.value = '25-180'
            } else if (answers.animalQuantity === '20,000 - 30,000') {
              records.waterConsumptionPerDay.value = '50-270'
            }
          } else if (answers.siteType === 'Sitio 2-3') {
            if (answers.animalQuantity === '50 - 10,000') {
              records.waterConsumptionPerDay.value = '1-75'
            } else if (answers.animalQuantity === '10,000 - 20,000') {
              records.waterConsumptionPerDay.value = '75-145'
            } else if (answers.animalQuantity === '20,000 - 30,000') {
              records.waterConsumptionPerDay.value = '145-220'
            }
          }
        } else if (answers.waterType === 'Agua Residual') {
          if (answers.residualWaterToBeTreated === 'Agua de Servicio') {
            if (answers.rangeOfPeople === '25 - 50') {
              records.proposedTechnology.value = 'Sistema Lagunar'
              records.waterFlowToBeTreatedPerDay.value = '4-10'
              records.biochemicalOxygenDemandConcentration.value = '300 – 600'
              records.requiredArea.value = '280'
              records.complianceWithStandard.value = 'NOM 001'
              records.investmentBaseBudget.value = '$550,000 - $700,000 M.N.'
            } else if (answers.rangeOfPeople === '50 - 100') {
              records.proposedTechnology.value = 'Sistema Lagunar'
              records.waterFlowToBeTreatedPerDay.value = '10-20'
              records.biochemicalOxygenDemandConcentration.value = '300 – 600'
              records.requiredArea.value = '410'
              records.complianceWithStandard.value = 'NOM 001'
              records.investmentBaseBudget.value = '$650,000 - $800,000 M.N.'
            } else if (answers.rangeOfPeople === '100 - 150') {
              records.proposedTechnology.value = 'Sistema Lagunar'
              records.waterFlowToBeTreatedPerDay.value = '20-30'
              records.biochemicalOxygenDemandConcentration.value = '300 – 600'
              records.requiredArea.value = '500'
              records.complianceWithStandard.value = 'NOM 001'
              records.investmentBaseBudget.value = '$800,000 - $900,000 M.N.'
            }
          } else if (
            answers.residualWaterToBeTreated === 'Agua Residual de Producción'
          ) {
            if (answers.siteType === 'Sitio 1') {
              if (answers.animalQuantity === '50 - 2,500') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '105'
                records.biochemicalOxygenDemandConcentration.value = '9,262'
                records.requiredArea.value = '65'
                records.investmentBaseBudget.value =
                  '$16,000,000 - $18,000,000 M.N.'
              } else if (answers.animalQuantity === '2,500 - 5,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '105 - 210'
                records.biochemicalOxygenDemandConcentration.value = '9,262'
                records.requiredArea.value = '126'
                records.investmentBaseBudget.value =
                  '$18,000,000 - $19,500,000 M.N.'
              } else if (answers.animalQuantity === '5,000 - 10,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '210 - 420'
                records.biochemicalOxygenDemandConcentration.value = '9,262'
                records.requiredArea.value = '252'
                records.investmentBaseBudget.value =
                  '$20,000,000 - $22,000,000 M.N.'
              }
            } else if (answers.siteType === 'Sitio 2') {
              if (answers.animalQuantity === '1,000 - 10,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '340'
                records.biochemicalOxygenDemandConcentration.value = '5,600'
                records.requiredArea.value = '212.4'
                records.investmentBaseBudget.value =
                  '$20,000,000 - $22,000,000 M.N.'
              } else if (answers.animalQuantity === '10,000 - 20,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '340 - 680'
                records.biochemicalOxygenDemandConcentration.value = '5,600'
                records.requiredArea.value = '424.8'
                records.investmentBaseBudget.value =
                  '$27,500,000 - $29,000,000 M.N.'
              } else if (answers.animalQuantity === '20,000 - 30,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '680 - 1,020'
                records.biochemicalOxygenDemandConcentration.value = '5,600'
                records.requiredArea.value = '637.2'
                records.investmentBaseBudget.value =
                  '$34,500,000 - $36,000,000 M.N.'
              }
            } else if (answers.siteType === 'Sitio 3') {
              if (answers.animalQuantity === '1,000 - 10,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '360'
                records.biochemicalOxygenDemandConcentration.value = '9,180'
                records.requiredArea.value = '216'
                records.investmentBaseBudget.value =
                  '$20,000,000 - $22,000,000 M.N.'
              } else if (answers.animalQuantity === '10,000 - 20,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '360 - 660'
                records.biochemicalOxygenDemandConcentration.value = '9,180'
                records.requiredArea.value = '396'
                records.investmentBaseBudget.value =
                  '$24,500,000 - $26,000,000 M.N.'
              } else if (answers.animalQuantity === '20,000 - 30,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '660 - 1030'
                records.biochemicalOxygenDemandConcentration.value = '9,180'
                records.requiredArea.value = '618'
                records.investmentBaseBudget.value =
                  '$33,500,000 - $35,000,000 M.N.'
              }
            } else if (answers.siteType === 'Sitio 2-3') {
              if (answers.animalQuantity === '1,000 - 10,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '360'
                records.biochemicalOxygenDemandConcentration.value = '9,000'
                records.requiredArea.value = '216'
                records.investmentBaseBudget.value =
                  '$20,000,000 - $22,000,000 M.N.'
              } else if (answers.animalQuantity === '10,000 - 20,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '360 - 720'
                records.biochemicalOxygenDemandConcentration.value = '9,000'
                records.requiredArea.value = '434'
                records.investmentBaseBudget.value =
                  '$27,500,000 - $29,000,000 M.N.'
              } else if (answers.animalQuantity === '20,000 - 30,000') {
                records.proposedTechnology.value = 'Biorreactor Anaerobio'
                records.waterFlowToBeTreatedPerDay.value = '720 - 1,080'
                records.biochemicalOxygenDemandConcentration.value = '9,000'
                records.requiredArea.value = '648'
                records.investmentBaseBudget.value =
                  '$35,000,000 - $39,000,000 M.N.'
              }
            }
          }
        }
      }
      if (answers.animalType === 'Res') {
        if (answers.animalQuantity === '100 - 300') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '180'
          records.biochemicalOxygenDemandConcentration.value = '2,000'
          records.requiredArea.value = '108'
          records.investmentBaseBudget.value = '$17,000,000 - $18,500,000 M.N.'
        } else if (answers.animalQuantity === '300 - 500') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '300'
          records.biochemicalOxygenDemandConcentration.value = '2,000'
          records.requiredArea.value = '180'
          records.investmentBaseBudget.value = '$19,000,000 - $20,000,000 M.N.'
        } else if (answers.animalQuantity === '500 - 800') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '480'
          records.biochemicalOxygenDemandConcentration.value = '2,000'
          records.requiredArea.value = '288'
          records.investmentBaseBudget.value = '$21,000,000 - $23,000,000 M.N.'
        }
      } else if (answers.animalType === 'Cerdo') {
        if (answers.animalQuantity === '100 - 300') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '135'
          records.biochemicalOxygenDemandConcentration.value = '1,250'
          records.requiredArea.value = '81'
          records.investmentBaseBudget.value = '$16,000,000 - $17,000,000 M.N.'
        } else if (answers.animalQuantity === '300 - 500') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '225'
          records.biochemicalOxygenDemandConcentration.value = '1,250'
          records.requiredArea.value = '135'
          records.investmentBaseBudget.value = '$19,000,000 - $21,000,000 M.N.'
        } else if (answers.animalQuantity === '500 - 800') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '360'
          records.biochemicalOxygenDemandConcentration.value = '1,250'
          records.requiredArea.value = '216'
          records.investmentBaseBudget.value = '$21,000,000 - $22,000,000 M.N.'
        }
      } else if (answers.animalType === 'Ave') {
        if (answers.animalQuantity === '100 - 300') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '10'
          records.biochemicalOxygenDemandConcentration.value = '1,550'
          records.requiredArea.value = '6'
          records.investmentBaseBudget.value = '$13,000,000 - $13,500,000 M.N.'
        } else if (answers.animalQuantity === '300 - 500') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '20'
          records.biochemicalOxygenDemandConcentration.value = '1,550'
          records.requiredArea.value = '12'
          records.investmentBaseBudget.value = '$13,500,000 - $14,000,000 M.N.'
        } else if (answers.animalQuantity === '500 - 800') {
          records.proposedTechnology.value = 'Biorreactor Anaerobio'
          records.waterFlowToBeTreatedPerDay.value = '300'
          records.biochemicalOxygenDemandConcentration.value = '1,550'
          records.requiredArea.value = '18'
          records.investmentBaseBudget.value = '$14,000,000 - $15,000,000 M.N.'
        }
      }
    } else if (answers.fieldOfBusiness === 'Industrial') {
      if (answers.typeOfWaterToBeTreated === 'Proceso') {
        records = {
          contactCompany: {
            id: 'contactCompany',
            title: 'contactCompany',
            value: null,
          },
        }
      }
      if (answers.typeOfWaterToBeTreated === 'Proceso') {
        records.contactCompany.value =
          'Para la cotización de un sistema de tratamiento de agua de proceso se requiere información específica de la calidad de agua. Lo invitamos a ponerse en contacto con nuestro departamento de ingeniería para poder apoyarlos.'
      }

      if (answers.numberOfShifts) {
        records = {
          proposedTechnology: {
            id: 'proposedTechnology',
            title: 'proposedTechnology',
            value: null,
          },
          wasteWaterFlowToBeTreated: {
            id: 'wasteWaterFlowToBeTreated',
            title: 'wasteWaterFlowToBeTreated',
            value: null,
            unit: 'm3',
          },
          biochemicalOxygenDemandConcentration: {
            id: 'biochemicalOxygenDemandConcentration',
            title: 'biochemicalOxygenDemandConcentration',
            value: null,
            unit: 'mg/L',
          },
          complianceWithTheStandard: {
            id: 'complianceWithTheStandard',
            title: 'complianceWithTheStandard',
            value: null,
          },
          requiredArea: {
            id: 'requiredArea',
            title: 'requiredArea',
            value: null,
            unit: 'm2',
          },
          investmentBaseBudget: {
            id: 'investmentBaseBudget',
            title: 'investmentBaseBudget',
            value: null,
          },
        }

        if (answers.numberOfShifts === 'Turno 1') {
          if (answers.shiftEmployerAmount === '10 - 100') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '1-10'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '1.6'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$600,000 - $800,000 M.N.'
          } else if (answers.shiftEmployerAmount === '100 - 500') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '10-50'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '7.7'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$1,700,000 - $2,000,000 M.N.'
          } else if (answers.shiftEmployerAmount === '500 - 1,000') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '50-100'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '15.5'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$2,800,000 - $3,500,000 M.N.'
          } else if (answers.shiftEmployerAmount === '1,000 - 2,000') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '100-200'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '30.7'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$4,000,000 - $5,000,000 M.N.'
          }
        } else if (answers.numberOfShifts === 'Turno 2') {
          if (answers.shiftEmployerAmount === '10 - 100') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '2-20'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '3.0'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$800,000 - $1,000,000 M.N.'
          } else if (answers.shiftEmployerAmount === '100 - 500') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '20-100'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '15.5'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$2,500,000 - $3,300,000 M.N.'
          } else if (answers.shiftEmployerAmount === '500 - 1,000') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '100-200'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '30.7'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$3,300,000 - $4,500,000 M.N.'
          } else if (answers.shiftEmployerAmount === '1,000 - 2,000') {
            records.proposedTechnology.value = 'MBBR'
            records.wasteWaterFlowToBeTreated.value = '200-400'
            records.biochemicalOxygenDemandConcentration.value = '300 – 600'
            records.requiredArea.value = '61.5'
            records.complianceWithTheStandard.value = 'NOM 003'
            records.investmentBaseBudget.value = '$4,500,000 - $5,300,000 M.N.'
          }
        }
      }
    }
    return records
  })

  const currentPosition = ref(0)

  const currentQuestion = computed(() => questions.value[currentPosition.value])

  const isCompleted = computed(
    () =>
      questions.value.every((question) => Boolean(question.value)) &&
      Object.values(results.value).every((result) => Boolean(result.value))
  )

  const isReviewing = ref(false)

  watch(isCompleted, (newValue) => {
    if (!newValue || isReviewing.value) return
    isReviewing.value = newValue
  })

  function moveToPreviousQuestion() {
    if (!currentPosition.value) return

    currentPosition.value--

    clearAnswer(currentQuestion.value.id)
  }

  function moveToNextQuestion() {
    if (isCompleted.value) return

    currentPosition.value++
  }

  function updateAnswer(id: string, value: string | number | null) {
    answers[id] = value

    moveToNextQuestion()
  }

  function clearAnswer(key: string) {
    if (key in answers) {
      answers[key] = null
    }
  }

  function clearAnswers() {
    for (const key in answers) {
      clearAnswer(key)
    }
  }

  function restart() {
    clearAnswers()
    currentPosition.value = 0
    isReviewing.value = false
  }

  return {
    answers,
    questions,
    results,
    isCompleted,
    isReviewing,
    currentPosition,
    currentQuestion,
    moveToPreviousQuestion,
    moveToNextQuestion,
    updateAnswer,
    clearAnswers,
    restart,
  }
}
