










































































































































































































































import { Result } from '@/composition/useQuotation'
import { useTechnologyQuote } from '@/composition/useTechnology'
import communique from '@/notification'
import { TechnologyQuoteSelectModel } from '@/services/modules/technology'
import i18n from '@/setup/i18n'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import QuotationResultItem from './QuotationResultItem.vue'
import BaseBox from '../_base/BaseBox.vue'
import BaseText from '../_base/BaseText.vue'

export default defineComponent({
  name: 'QuotationResidentialResult',

  components: {
    QuotationResultItem,
    ValidationObserver,
    ValidationProvider,
    BaseBox,
    BaseText,
  },

  props: {
    results: {
      type: Object as PropType<Record<string, Result>>,
      required: true,
    },
    answers: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },

  emits: {
    restart: null,
  },

  setup(props, context) {
    const form = ref<HTMLFormElement | null>(null)
    const email = ref('')

    function onRestart() {
      context.emit('restart')
    }

    const quoteModel = computed(() => {
      const model: TechnologyQuoteSelectModel = {
        proposedTechnology: '',
        wasteWaterFlowToBeTreated: '',
        biochemicalOxygenDemandConcentration: '',
        complianceWithTheStandard: '',
        requiredArea: '',
        investmentBaseBudget: '',
        waterFlowToBeTreatedPerDay: '',
        complianceWithStandard: '',
        fieldOfBusiness: '',
        residentialType: '',
        rangeOfPeople: '',
        commerceType: '',
        waterType: '',
        sectorType: '',
        typeOfBird: '',
        animalQuantity: '',
        tonsOfChickensPerDay: '',
        siteType: '',
        animalType: '',
        numberOfShifts: '',
        shiftEmployerAmount: '',
        waterConsumptionPerDay: '',
        email: '',
      }

      for (const key in props.results) {
        const resultKey = key as keyof TechnologyQuoteSelectModel
        const result = props.results[key]
        let value = result.value ? result.value.toString() : null

        if (result.unit) value = `${value} ${result.unit}`

        if (!(resultKey in model)) continue

        model[resultKey] = value
      }
      for (const key in props.answers) {
        const resultKey = key as keyof TechnologyQuoteSelectModel
        const result = props.answers[key]

        let value = result ? result.toString() : null

        if (!(resultKey in model)) continue

        model[resultKey] = value
      }

      model.email = email.value
      return model
    })

    const isSendingEmail = ref(false)
    const isDownloading = ref(false)
    const canGenerateQuote = computed(
      () =>
        quoteModel.value.proposedTechnology ||
        quoteModel.value.waterConsumptionPerDay ||
        quoteModel.value.waterFlowToBeTreatedPerDay
    )

    async function quote(isSendToEmail?: boolean) {
      try {
        if (isSendToEmail) {
          isSendingEmail.value = true
        } else {
          isDownloading.value = true
        }

        await useTechnologyQuote(quoteModel.value)

        if (isSendToEmail) {
          email.value = ''
          form.value?.reset()
        }

        communique.dispatch({
          variant: 'success',
          message: i18n.t(
            isSendToEmail
              ? 'quoteResultFields.sendSuccess'
              : 'quoteResultFields.downloadSuccess'
          ) as string,
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t(
            isSendToEmail
              ? 'quoteResultFields.sendError'
              : 'quoteResultFields.downloadError'
          ) as string,
        })
      } finally {
        if (isSendToEmail) {
          isSendingEmail.value = false
        } else {
          isDownloading.value = false
        }
      }
    }

    const emailLabel = computed(
      () => i18n.t('quoteResultFields.email.label') as string
    )

    return {
      form,
      onRestart,
      quote,
      isSendingEmail,
      isDownloading,
      emailLabel,
      email,
      canGenerateQuote,
    }
  },
})
