






import QuotationForm from '@/components/quotation/QuotationForm.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Quotation',

  components: {
    QuotationForm,
  },
})
